<template>
  <div @change="onChange">
    <AInput label="Name" v-model="state.name" :isValid="isValid('name')" :errors="getErrors('name')" />
    <AInput label="Position" v-model="state.position" :isValid="isValid('position')" :errors="getErrors('position')" />
    <AMaskedInput :horizontal="{ input: 'col-sm-5' }" label="Email" v-model="state.email" v-lowercase mask="email" :isValid="isValid('email')" :errors="getErrors('email')" />
    <AMaskedInput :horizontal="{ input: 'col-sm-5' }" label="Phone" v-model="state.phone" mask="phone" />
    <AMaskedInput :horizontal="{ input: 'col-sm-5' }" label="Cellphone" v-model="state.cellphone" mask="phone" />
    <ACheckBox label="Active" v-model="state._status" />
  </div>
</template>

<script>
export default {
  name: 'ManagerContact',
  props: {
    contact: {
      type: Object,
      required: true
    },
    validation: {
      type: Array,
      default: () => ['', false, false]
    }
  },
  watch: {
    contact(val) {
      this.state = val
    }
  },
  data() {
    return {
      state: { ...this.contact }
    }
  },
  methods: {
    onSubmit(e) {
      //console.log('SUBMIT')
    },
    onChange(e) {
      console.log('CHANGE')
      this.$emit('contactChanged', this.state, e)
    },
    isValid(field) {
      if (!this.validation[1]) return true
      return this.validation[1](this.validation[0] + field)
    },
    getErrors(field) {
      if (!this.validation[2]) return []
      return this.validation[2](this.validation[0] + field)
    }
  }
}
</script>
