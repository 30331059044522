<template>
  <CRow>
    <CCol col="12">
      <CToaster :autohide="8000">
        <template v-for="(alert, index) in alerts">
          <CToast :show="true" :color="alert.type" :key="index"> {{ $t(alert.message) }} </CToast>
        </template>
      </CToaster>

      <div v-if="cropper.media">
        <ACropper :id="cropper.type" :media="cropper.media" :options="cropper.options" @imageCropped="onImageCropped" />
      </div>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit manager') + ` id: ${form.id}` : $t('Create manager') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <CTabs variant="pills" :active-tab="0">
              <CTab :title="$t('Manager')">
                <ACard title="Basic Information">
                  <AInput label="Name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />
                  <AInput label="Real name" v-model="form.real_name" :isValid="isValid('real_name')" :errors="getErrors('real_name')" />
                  <ACheckBox label="Active" placeholder="Enable or disable de current manager" v-model="form._status" />
                </ACard>

                <ACard title="Identification and Location">
                  <AMultiSelect :horizontal="{ input: 'col-sm-5', label: 'col-sm-3' }" label="Identification type" v-model="form.identification_type_id" :options="lists.identification_types" :isValid="isValid('identification_type_id')" :errors="getErrors('identification_type_id')" />
                  <AMaskedInput :horizontal="{ input: 'col-sm-5' }" label="Identification" maxLength="14" v-model="form.identification" :mask="form.identification_type_id === 31 ? 'NIT' : 'ID'" :isValid="isValid('identification')" :errors="getErrors('identification')" />
                  <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="City" v-model="form.city_id" :options="lists.cities" :isValid="isValid('city_id')" :errors="getErrors('city_id')" />
                  <AInput label="Address" v-model="form.address" :isValid="isValid('address')" :errors="getErrors('address')" />
                  <AMaskedInput :horizontal="{ input: 'col-sm-5' }" label="Phone" v-model="form.phone" mask="phone" :isValid="isValid('phone')" :errors="getErrors('phone')" />
                  <AMaskedInput :horizontal="{ input: 'col-sm-5' }" label="Email" v-model="form.email" v-lowercase mask="email" :isValid="isValid('email')" :errors="getErrors('email')" />
                </ACard>

                <ACard title="Logo">
                  <div v-if="images.current.logo" class="p-2 border bg-light text-center" style="overflow: auto;">
                    <div class="" style="height: auto;">
                      <img class="" :src="images.current.logo.url" style="width: 100%; max-width: 300px;" click="cropImage('current', 'logo', { aspectRatio: 1, folder: 'managers' })" />
                    </div>
                  </div>
                  <CInputFile class="mt-2" :value="cropper.file" :horizontal="{ input: 'col-lg-4 col-sm-6' }" type="file" @change="(files, event) => handleFileSelected('logo', files, event, { aspectRatio: 1, folder: 'managers' })" :placeholder="$t('New file')" :custom="true" accept="image/x-png,image/jpeg" />
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" :title="$t('Contacts')">
                <ACard>
                  <div v-for="(contact, index) of form.contacts" :key="index">
                    <transition name="fade">
                      <ACard :title="$t('Contact') + ` ${index + 1}`">
                        <ManagerContact :contact="contact" :validation="[`contacts.$each.${index}.`, isValid, getErrors]" @contactChanged="value => $set(form.contacts, index, value)" />

                        <ACard title="Signature">
                          <div v-if="images.contacts[`signature_${index}`]" class="p-2 border bg-light text-center" style="overflow: auto;">
                            <div class="" style="height: auto;">
                              <img class="" :src="images.contacts[`signature_${index}`].url" style="width: 100%; max-width: 300px;" click="cropImage('current', `signature_${index}`, { aspectRatio: false, aspectRatio: 3.1, folder: 'manager_contacts', destination: 'contacts', related_id: contact.id })" />
                            </div>
                          </div>
                          <CInputFile v-if="contact.id" class="mt-2" :value="cropper.file" :horizontal="{ input: 'col-lg-4 col-sm-6' }" type="file" @change="(files, event) => handleFileSelected(`signature_${index}`, files, event, { aspectRatio: false, initialAspectRatio: 3.1, folder: 'manager_contacts', destination: 'contacts', related_id: contact.id })" :placeholder="$t('New file')" :custom="true" accept="image/x-png,image/jpeg" />
                          <Label v-else class="text-danger">{{ $t('Debe salvar primero para poder agregar la firma') }}</Label>
                        </ACard>

                        <CCardFooter>
                          <CButton color="danger" class="pull-right" @click="removeContact(index, contact)">{{ '- ' + $t('Remove contact') }}</CButton>
                        </CCardFooter>
                      </ACard>
                    </transition>
                  </div>
                  <CCardFooter>
                    <CButton color="primary" class="pull-right" @click="addContact">{{ '+ ' + $t('Add contact') }}</CButton>
                  </CCardFooter>
                </ACard>
              </CTab>
            </CTabs>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'
import filesMixin from '@/app/views/_mixins/files-mixin'

import ManagerContact from './elements/ManagerContact'

import { DEBUG } from '@/config/config'

export default {
  name: 'ManagerForm',
  mixins: [formMixin, filesMixin],
  components: {
    ManagerContact
  },
  props: {
    caption: {
      type: String,
      default: 'Manager id'
    }
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, contacts: [] },

      // Extra
      lists: {},
      images: { current: {}, temp: {}, contacts: {} },

      // Helpers
      alerts: [],
      cropper: { file: '', type: '', modal: false, media: {}, options: {} },
      validators: {}
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/managers' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.manager ? data.manager : { id: 0 }
      this.lists = data._lists || {}

      this.parseView()
      this.parseMedia()
      this.parseContacts()

      this.validators = {
        main: data._validation || {},
        extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'Manager']: options })
    },
    parseMedia() {
      if (this.form.media) {
        for (const media of this.form.media) {
          this.$set(this.images.current, media.pivot_type, media)
        }
      }
    },
    parseContacts() {
      if (this.form.contacts && this.form.contacts.length) {
        for (const idx in this.form.contacts) {
          for (const media of this.form.contacts[idx].media) {
            this.$set(this.images.contacts, media.pivot_type + `_${idx}`, media)
          }
        }
        this.validateContacts()
      }
      console.log(this.images)
    },

    // Contacts
    addContact() {
      if (this.form.contacts.length < 10) {
        this.form.contacts.push({ id: 0 })
      }
      if (this.form.contacts.length === 1) this.validateContacts()
    },
    removeContact(index, contact) {
      if (contact.id) this.form.remove_contacts = [...(this.form.remove_contacts || []), ...[contact.id]]
      this.form.contacts.splice(index, 1)
      if (this.form.contacts.length === 0) this.removeValidator('contacts')
    },
    validateContacts() {
      this.$nextTick(() => {
        if (this.validators?.extra?.contacts) this.injectValidator('contacts', 0)
      })
    },

    // SUBMIT

    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      const self = this
      // TODO: cleaning function
      self.form = self.stripNumbers(self.form, ['identification', 'phone'])
      self.$http[self.form.id ? 'put' : 'post']('admin/managers', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/managers/${response.data.manager.id}/edit`).catch(() => {})
          self.showAlert(`Manager ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the manager.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
